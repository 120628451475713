/*
 * @Author: lixiao
 * @Email: l3936525@163.com
 * @Filename: webpanel/src/apis/aisep/ticket/index.js
 * @Date: 2021-08-01 17:33:43
 * @Last Modified by: lixiao
 * @Last Modified time: 2021-11-02 10:56:42
 * @Description: 
 */

import { get, post } from "webasercomps/src/assets/httpClient"
import { organizeParams, updateSheetRecord } from "webasercomps/src/apis/utils";

const tmh = 'ticket-manage';

function addTicket({options, state, config}){
	return new Promise((resolve, reject) => {
		post({
			url: '/v3/aisep/ticket/create',
			data: options.data,
			config
		}).then(resp => {
			if(resp.ok){
				let order = resp.result.order;
				order.data = JSON.parse(order.data);
				
				if(order){
					resolve(updateSheetRecord({
						handle: tmh,
						operate: 'unshift',
						newRecords: [order],
						state
					}));
				}else{
					resolve();
				}
			}else{
				reject(resp);
			}
		}).catch(err => reject(err));
	});
}

function modifyTicket({options, state, config}){
	return new Promise((resolve, reject) => {
		post({
			url: '/v3/aisep/ticket/modify',
			data: options.data,
			params: options.params,
			config
		}).then(resp => {
			if(resp.ok){
				resolve(resp.result);
			}else{
				reject(resp);
			}
		}).catch(err => reject(err));
	});
}

function fetchTickets({options, state, config}){
	return new Promise((resolve, reject) => {
		let params = organizeParams(options.params);
		get({
			url: '/v3/aisep/ticket/fetch',
			params,
			config
		}).then(resp => {
			if(resp.ok){
				resolve(updateSheetRecord({
					handle: options.handle,
					operate: 'refresh',
					newRecords: resp.result.map(record => {
						record.data = JSON.parse(record.data);
						record.items = [];
						return record;
					}),
					page: resp.page,
					state
				}));
			}else{
				reject(resp);
			}
		}).catch(err => reject(err));
	})
}

export {
	addTicket,
	modifyTicket,
	fetchTickets
}

export const eps = {
	CREATE_TICKET: '/aisep/add/ticket',
	MODIFY_TICKET: '/aisep/modify/ticket',
	FETCH_TICKETS: '/aisep/fetch/tickets',
}