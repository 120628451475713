/*
 * @Author: lixiao
 * @Email: l3936525@163.com
 * @Filename: webpanel/config/config.js
 * @Date: 2021-04-26 15:40:52
 * @Last Modified by: lixiao
 * @Last Modified time: 2021-11-10 15:09:31
 * @Description: 
 */
import config from 'webasercomps/src/defaultConfig';

let BASE_URL = 'http://test.junshikj.cn';
let LOGIN_URL = 'http://test.junshikj.cn/login.html';

if(__webaser_dev__){
	BASE_URL = 'http://local.szjs.webaser.cn:9000';
	LOGIN_URL = 'http://local.szjs.webaser.cn:9000/login.html';
}

config.devTool = false; // redux dev tool
config.logo = {
	title: '深圳珺实',
}

config.api = {
	config: {
		context: '/szjs'
	}
}

config.urls = {
	...config.urls,
	base: BASE_URL,
	login: LOGIN_URL
}

export default config;