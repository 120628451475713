import {
	apis,
	callApi
} from 'webasercomps/src/apis';

import * as aisep from './aisep/ticket';
import * as auto from './aisep/model/auto';

import * as themeRef from './aisep/theme/ref';

const aisepApis = Object.assign({}, apis, {
	[aisep.eps.CREATE_TICKET]: aisep.addTicket,
	[aisep.eps.MODIFY_TICKET]: aisep.modifyTicket,
	[aisep.eps.FETCH_TICKETS]: aisep.fetchTickets,
	[auto.eps.FETCH_AUTO_MODELS]: auto.fetch,
	[auto.eps.CREATE_AUTO_MODEL]: auto.create,
	[auto.eps.MODIFY_AUTO_MODEL]: auto.modify,
	[themeRef.eps.FETCH_THEME_REFS]: themeRef.fetch,
	[themeRef.eps.BIND_THEME_REF]: themeRef.create,
});

export const api = callApi(aisepApis);